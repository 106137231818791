
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
export type msgCodeType =
  | "sign_in"
  | "login"
  | "reset_password"
  | "aftersalesman_login"
  | "unlock_room"
  | null;

@Component({
  components: {},
})
export default class gVerify extends Vue {
  @Prop() seconds!: number;
  @Prop() start!: boolean;
  private left = 0;

  get verifyText(): string {
    return this.start && this.seconds
      ? `${this.left}s后重新获取`
      : "获取验证码";
  }

  @Watch("start")
  getStart(value: boolean | undefined): void {
    if (value) {
      this.countdown();
    }
  }

  countdown(): void {
    this.left = this.seconds;
    let counter = setInterval(() => {
      if (--this.left < 1) {
        this.left = this.seconds;
        clearInterval(counter);
        this.$emit("count-end");
      }
    }, 1000);
  }

  sendMsgCode(): void {
    if (this.start) {
      return;
    } else {
      this.$emit("send-code");
    }
  }
}
