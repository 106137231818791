
import { Vue, Component } from "vue-property-decorator";
import region from "../components/region.vue";
import verify from "../components/verify.vue";
import MD5 from "md5";
import { Sign } from "@/assets/js/Signature";
import { Mutation, State } from "vuex-class";
import { Toast } from "vant";
@Component({
  components: {
    region,
    verify,
  },
})
export default class FFLogin extends Vue {
  private account = "";
  private password = "";
  private confirm = "";
  private verify = "";
  private isMsgCodeSent = false;
  private Platform = "guigui_web";
  private areaCode = localStorage.getItem("area_code") || "+86";
  private webregist = false;

  @Mutation updateUserInfo: any;
  @State userinfo: any;

  toIndex() {
    this.$router.push({
      name: "FF-Index",
    });
  }

  // 登录
  login() {
    // login
    if (this.checkAccount() && this.checkPassword()) {
      let data = {
        phone: this.account,
        password: MD5(this.password),
        platform: this.Platform,
        area_code: this.areaCode,
      };
      this.$post("/login_guigui", data, {
        headers: { sign: Sign(data) },
      }).then((res: any) => {
        if (res.data.status == 1) {
          Toast("登录成功！");
          this.updateUserInfo(res.data.data);
          this.$router.push({ name: "FF-Index" });
        }
      });
    } else {
      Toast("请输入内容");
    }
  }

  // 注册
  regist() {
    if (this.checkAccount() && this.checkConfirm() && this.checkVerify()) {
      let data = {
        phone: this.account,
        area_code: this.areaCode,
        password: MD5(this.password),
        repeat_password: MD5(this.confirm),
        sms_code: this.verify,
        platform: this.Platform,
        invitation_code: "",
      };

      this.$post("/sign_in_guigui_account", data, {
        headers: {
          sign: Sign(data),
        },
      }).then((res: any) => {
        console.log(res, "----");
        Toast("注册成功,请登录！");
        this.webregist = true;
      });
    } else {
      Toast("请输入内容");
    }
  }

  // 地区号码
  setAreaCode(code: any): void {
    this.areaCode = code;
  }

  // 验证码
  sendMsgCode(): void {
    // 检测输入的内容
    let data = {
      phone: this.account,
      type: "",
      area_code: this.areaCode,
      platform: this.Platform,
    };
    if (this.checkAccount()) {
      // 先判断账号是否已被注册
      let checkData = {
        phone: this.account,
        area_code: this.areaCode,
      };
      this.$post("/check_phone_number", checkData).then((res: any) => {
        if (res.data.status == 1) {
          // 未注册则发送验证码
          data.type = "sign_in";
          this.$post("/send_sms_code", data, {
            headers: { sign: Sign(data) },
          }).then((res: any) => {
            if (res.data.status == 1) {
              this.$message({ type: "success", message: res.data.msg });
              this.isMsgCodeSent = true;
            } else {
              this.$message({ type: "error", message: res.data.msg });
              this.isMsgCodeSent = false;
            }
          });
        } else {
          Toast("账号已注册！");
        }
      });
    } else {
      Toast("请输入账号！");
    }
  }

  // 检查账号是否为空
  checkAccount(): boolean {
    if (this.account !== "") {
      return true;
    } else {
      return false;
    }
  }

  // 检查密码是否为空
  checkPassword(): boolean {
    if (this.password !== "") {
      return true;
    } else {
      return false;
    }
  }

  // 检查确认密码和密码是否一致
  checkConfirm(): boolean {
    if (
      this.password !== "" &&
      this.confirm !== "" &&
      this.confirm === this.password
    ) {
      return true;
    } else {
      return false;
    }
  }

  // 检查验证码是否为空
  checkVerify(): boolean {
    if (this.isMsgCodeSent) {
      Toast("请先发送验证码");
      return false;
    }
    if (this.verify !== "") {
      return true;
    } else {
      return false;
    }
  }
}
