
import { Component, Vue } from "vue-property-decorator";

@Component
export default class gRegion extends Vue {
  areaList: Array<any> = [];
  index = -1;
  isShow = false;
  // 获取默认值
  default = localStorage.getItem("area_code") || "+86";

  get curArea(): string | any {
    return this.index != -1
      ? this.areaList[this.index].area_code
      : this.default;
  }
  selectArea(): void {
    this.isShow = !this.isShow;
  }
  pick(index: number) {
    this.index = index;
    localStorage.setItem("area_code", this.curArea);
    this.$emit("area-change", this.curArea);
  }

  mounted() {
    this.$get("https://ggtools.thinkerx.com/get_area_phone_code").then(
      (res: any) => {
        if (res.data.status == 1) {
          this.areaList = res.data.data;
        }
      }
    );
  }
}
