import JWA from "jwa";
import jsrsasign  from 'jsrsasign';
import base64url from "base64url";

const privateKey = `-----BEGIN PRIVATE KEY-----\n
MIICeQIBADANBgkqhkiG9w0BAQEFAASCAmMwggJfAgEAAoGBAK5eOnCsq8RGu0EV\n
/2Ojott5txP7FKf2Bvo/f4rjZm8G8rgtMqFoXwxl4wpvvo3M1m8yUp2P+O2P5fro\n
zpQwXcmah16W0PHQUv1qpYutxFZoNrhjsO5X8t9ROUyRhggmF19dFDH/Cuc46K5b\n
FfgFqmdH84nwo5k24xWIP20r9xIZAgMBAAECgYEAmyv78d2YFlmjtq4u0qsC+TH9\n
/OgKX9aQKw9rH1xzAgc65TBDyGQrgcsCG32KJILPqbEs2E8RhcfzMDER9cp9Cr2Q\n
5CW492Fc4SNrRESEaOw0Qg9N79KAtIAL/qXuxaQg0FMpuddvG55na4m7LKgw6+Gv\n
ChJgaakKSrDukcje2qECQQDhEW4cacy7QWDGuPs4M+QvNEdJ4yEU2KzyNDBZQbPB\n
CRUZdsVIFoxhtcZsfmjNzEJS7cP37r3AhnJweNY0CaEtAkEAxlUFGxGJlwMdpOoQ\n
TnpTcPywxFVDZavOn2Jh6BecsYJBtrAXxjL+/5pz59sDsKPleYAHVSVHb5E2/Blq\n
PiEQHQJBAKPjN1UFTYmFf9z00ioIzz34SXjqJSqy5KyxiAtWpngIDw8tx1ZI5Qw5\n
ezWBjvtco2cTr8GYXSAWRrrTotbdtDkCQQDCtnOFyKjxFkEDSDxsH5JN6fdNOXwq\n
lAsN1VPTvWK7rur/BVDB6A0LOkKyjQXJZI8EwtnwEOJaORHnXLg+eM1NAkEAvhZE\n
hBRKMXAF1FzJLjKgJw/ibc+nle0Bz6qX4/bd2BlzyZyIEC/hLKZwBOv8ZMZJTJAH\n
zrsdbU5HAqvhp0JpKA==\n
-----END PRIVATE KEY-----`;

export function Sign(data: any): string {
    const signature = JWA("RS256").sign(JSON.stringify(data), privateKey);
    return encodeURI(base64url.toBase64(signature));
}

const priK = `-----BEGIN PRIVATE KEY-----
    MIICeQIBADANBgkqhkiG9w0BAQEFAASCAmMwggJfAgEAAoGBAK5eOnCsq8RGu0EV
    /2Ojott5txP7FKf2Bvo/f4rjZm8G8rgtMqFoXwxl4wpvvo3M1m8yUp2P+O2P5fro
    zpQwXcmah16W0PHQUv1qpYutxFZoNrhjsO5X8t9ROUyRhggmF19dFDH/Cuc46K5b
    FfgFqmdH84nwo5k24xWIP20r9xIZAgMBAAECgYEAmyv78d2YFlmjtq4u0qsC+TH9
    /OgKX9aQKw9rH1xzAgc65TBDyGQrgcsCG32KJILPqbEs2E8RhcfzMDER9cp9Cr2Q
    5CW492Fc4SNrRESEaOw0Qg9N79KAtIAL/qXuxaQg0FMpuddvG55na4m7LKgw6+Gv
    ChJgaakKSrDukcje2qECQQDhEW4cacy7QWDGuPs4M+QvNEdJ4yEU2KzyNDBZQbPB
    CRUZdsVIFoxhtcZsfmjNzEJS7cP37r3AhnJweNY0CaEtAkEAxlUFGxGJlwMdpOoQ
    TnpTcPywxFVDZavOn2Jh6BecsYJBtrAXxjL+/5pz59sDsKPleYAHVSVHb5E2/Blq
    PiEQHQJBAKPjN1UFTYmFf9z00ioIzz34SXjqJSqy5KyxiAtWpngIDw8tx1ZI5Qw5
    ezWBjvtco2cTr8GYXSAWRrrTotbdtDkCQQDCtnOFyKjxFkEDSDxsH5JN6fdNOXwq
    lAsN1VPTvWK7rur/BVDB6A0LOkKyjQXJZI8EwtnwEOJaORHnXLg+eM1NAkEAvhZE
    hBRKMXAF1FzJLjKgJw/ibc+nle0Bz6qX4/bd2BlzyZyIEC/hLKZwBOv8ZMZJTJAH
    zrsdbU5HAqvhp0JpKA==
    -----END PRIVATE KEY-----`;
export function calcKeyByRSA(newStr: any){
    newStr = calcRsaKey(newStr)
    // 将密钥转码
    console.log(newStr)
    const rsa = jsrsasign.KEYUTIL.getKey(priK)
    const sig = new jsrsasign.KJUR.crypto.Signature({"alg": "SHA256withRSA"});
    // 初始化
    sig.init(rsa)
    // 传入待加密字符串
    sig.updateString(newStr)
    // 生成密文
    let sign = jsrsasign.hextob64(sig.sign());
    // 对加密后内容进行URI编码
    sign = encodeURIComponent(sign);
    //把参数与密文拼接好，返回
    const params = sign;
    return params
}
function calcRsaKey(obj: any){
    let str = ''
    const arr = Object.keys(obj)
    console.log(obj)
    // 按键名从小到大排序，验签传参需排序
    const sortArr = arr.sort( (a,b) => a.localeCompare(b))
    for(let i = 0; i < sortArr.length; i++){
        str += `&${sortArr[i]}=${obj[sortArr[i]]}`
    }
    str = str.slice(1)
    return str
}